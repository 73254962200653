import { generateBinaryRoleIds } from "../globals/helpers";
import { resolveClassifier } from "./settings.service";

const parseJSON = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error parsing JSON for key ${key}:`, error);
    return null;
  }
};
const setJSON = (key, value) => {
  if (value != null) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};
const getBoolean = (key) =>
  sessionStorage.getItem(key) === "true" ? "true" : "false"; // REFACTOR: fix this everywhere it's used
const setBoolean = (key, value) =>
  sessionStorage.setItem(key, value ? "true" : "false"); // REFACTOR: fix this everywhere it's used
const getString = (key) => sessionStorage.getItem(key) || "";
const setString = (key, value) => sessionStorage.setItem(key, value || "");

const STORAGE_KEYS = {
  USER_SETTINGS: "userSettings",
  NAME: "name",
  SID: "sid",
  ROLE_ID: "role_id",
  CONTRIBUTOR_ID: "contributor_id",
  CONTRIBUTOR_TYPE: "contributor_type",
  CONTRIBUTOR_NAME: "contributor_name",
  IS_ROOT: "is_root",
  MS_SSO: "ms_sso",
  PASSWORD_CHANGE: "password_change",
  ROLES: "roles",
  FULL_FACTS: "full_facts",
  BOL_JOYRIDE_STATUS: "bol-joyride-status",
  JOYRIDE_STATUS: "joyride-status",
  SOURCE_JOYRIDE_STATUS: "source-joyride-status",
  CONTRIBUTORS: "contributors",
  OIL_TYPES: "oil_types",
  EXPORT_BOL_STRUCTURE: "export_bol_structure",
  CONTRIBUTOR_LOCATIONS: "contributor_locations",
  BOL_STATUSES: "bol_statuses",
};

const storageMetadata = [
  {
    methodName: "AllUserSettings",
    storageKey: STORAGE_KEYS.USER_SETTINGS,
    type: "object",
  },
  { methodName: "Sid", storageKey: STORAGE_KEYS.SID, type: "string" },
  { methodName: "RoleId", storageKey: STORAGE_KEYS.ROLE_ID, type: "string" },
  {
    methodName: "Contributors",
    storageKey: STORAGE_KEYS.CONTRIBUTORS,
    type: "object",
  },
  {
    methodName: "OilType",
    storageKey: STORAGE_KEYS.OIL_TYPES,
    type: "object",
  },
  {
    methodName: "BolStatuses",
    storageKey: STORAGE_KEYS.BOL_STATUSES,
    type: "object",
  },
  {
    methodName: "PasswordChange",
    storageKey: STORAGE_KEYS.PASSWORD_CHANGE,
    type: "boolean",
  },
  {
    methodName: "JoyRideStatus",
    storageKey: STORAGE_KEYS.JOYRIDE_STATUS,
    type: "string",
  },
  {
    methodName: "BolJoyRideStatus",
    storageKey: STORAGE_KEYS.BOL_JOYRIDE_STATUS,
    type: "string",
  },
  {
    methodName: "SourceJoyRideStatus",
    storageKey: STORAGE_KEYS.SOURCE_JOYRIDE_STATUS,
    type: "string",
  },
  {
    methodName: "ContributorLocations",
    storageKey: STORAGE_KEYS.CONTRIBUTOR_LOCATIONS,
    type: "object",
  },
  {
    methodName: "ExportBolStructure",
    storageKey: STORAGE_KEYS.EXPORT_BOL_STRUCTURE,
    type: "object",
  },
  {
    methodName: "ContributorName",
    storageKey: STORAGE_KEYS.CONTRIBUTOR_NAME,
    type: "string",
  },
  {
    methodName: "Contributors",
    storageKey: STORAGE_KEYS.CONTRIBUTORS,
    type: "object",
  },
  {
    methodName: "OilType",
    storageKey: STORAGE_KEYS.OIL_TYPES,
    type: "object",
  },
  {
    methodName: "ExportBolStructure",
    storageKey: STORAGE_KEYS.EXPORT_BOL_STRUCTURE,
    type: "object",
  },
  {
    methodName: "ContributorLocations",
    storageKey: STORAGE_KEYS.CONTRIBUTOR_LOCATIONS,
    type: "object",
  },
  {
    methodName: "BolStatuses",
    storageKey: STORAGE_KEYS.BOL_STATUSES,
    type: "object",
  },
];

const generateStorageMethods = (storageMetadata) => {
  return storageMetadata.reduce((methods, { methodName, storageKey, type }) => {
    const config = [
      {
        prefix: "set",
        methods: { boolean: setBoolean, object: setJSON, string: setString },
      },
      {
        prefix: "get",
        methods: { boolean: getBoolean, object: parseJSON, string: getString },
      },
    ];

    config.forEach(
      ({ prefix, methods: { [type]: method } }) =>
        (methods[`${prefix}${methodName}`] =
          prefix === "set"
            ? (value) => method(storageKey, value)
            : () => method(storageKey))
    );

    return methods;
  }, {});
};

const storageMethods = generateStorageMethods(storageMetadata);

// REFACTOR: rationalize this later
const getUser = () => {
  return {
    name: sessionStorage.getItem(STORAGE_KEYS.NAME),
    sid: sessionStorage.getItem(STORAGE_KEYS.SID),
    role_id: sessionStorage.getItem(STORAGE_KEYS.ROLE_ID),
    contributor_id: sessionStorage.getItem(STORAGE_KEYS.CONTRIBUTOR_ID),
    contributor_type: sessionStorage.getItem(STORAGE_KEYS.CONTRIBUTOR_TYPE),
    contributor_name: sessionStorage.getItem(STORAGE_KEYS.CONTRIBUTOR_NAME),
    is_root: sessionStorage.getItem(STORAGE_KEYS.IS_ROOT),
    ms_sso: sessionStorage.getItem(STORAGE_KEYS.MS_SSO),
    password_change: sessionStorage.getItem(STORAGE_KEYS.PASSWORD_CHANGE),
    roles: parseJSON(STORAGE_KEYS.ROLES),
    full_facts: sessionStorage.getItem(STORAGE_KEYS.FULL_FACTS),
  };
};

// REFACTOR: rationalize this later
const setUser = (user) => {
  const {
    name,
    sid,
    role_id,
    contributor_id,
    contributor_type,
    is_root,
    contributor_name = "name",
    password_change,
    ms_sso,
    full_facts,
  } = user;

  const GeneratedBinaryCodeIntoArrayRolesIds = generateBinaryRoleIds(role_id);
  const hasRoles = GeneratedBinaryCodeIntoArrayRolesIds?.filter((id) => (role_id & id) === id) || [];

  setJSON(STORAGE_KEYS.ROLES, hasRoles);
  sessionStorage.setItem(STORAGE_KEYS.NAME, name);
  sessionStorage.setItem(STORAGE_KEYS.SID, sid);
  sessionStorage.setItem(STORAGE_KEYS.ROLE_ID, role_id);
  sessionStorage.setItem(STORAGE_KEYS.CONTRIBUTOR_ID, contributor_id);
  sessionStorage.setItem(STORAGE_KEYS.CONTRIBUTOR_TYPE, contributor_type);
  sessionStorage.setItem(STORAGE_KEYS.CONTRIBUTOR_NAME, contributor_name);
  sessionStorage.setItem(STORAGE_KEYS.IS_ROOT, is_root);
  sessionStorage.setItem(STORAGE_KEYS.MS_SSO, ms_sso);
  sessionStorage.setItem(STORAGE_KEYS.PASSWORD_CHANGE, password_change);
  sessionStorage.setItem(STORAGE_KEYS.FULL_FACTS, full_facts);
  return true;
};

const removeUser = () => {
  Object.values(STORAGE_KEYS).forEach((key) => {
    sessionStorage.removeItem(key);
  });
};

const updateUserSetting = (newSetting) => {
  const { classifier: _classifier, json_object } = newSetting;
  const classifier = resolveClassifier(_classifier);
  if (!classifier) return;

  const allUserSettings = storageMethods.getAllUserSettings();
  if (!allUserSettings) return;

  const newSettings = allUserSettings.map((setting) =>
    setting.classifier === classifier
      ? { classifier, json_object, exists: true }
      : setting
  );
  storageMethods.setAllUserSettings(newSettings);
  return "updated";
};

const getUserSetting = (_classifier) => {
  const classifier = resolveClassifier(_classifier);
  if (!classifier) return;

  const allUserSettings = storageMethods.getAllUserSettings();
  if (!allUserSettings) return;

  return allUserSettings.find((setting) => setting.classifier === classifier);
};

const StorageService = {
  ...storageMethods,
  getUser,
  setUser,
  removeUser,
  updateUserSetting,
  getUserSetting,
};

export default StorageService;
