import { userSettingClassifier } from "../globals/userSettingClassifier";
import { makeApiCall } from "./makeApiCall";

/**
 * Resolves a classifier string or numeric identifier into a numeric classifier.
 *
 * @param {_classifier} - The classifier string or numeric identifier to resolve.
 * @returns {number|null} The resolved classifier, or null if the classifier is invalid.
 */
export const resolveClassifier = (_classifier) => {
  if (!_classifier) {
    console.error("classifier must set");
    return null;
  }

  let classifier = _classifier;
  if (typeof _classifier === "string" && _classifier in userSettingClassifier) {
    classifier = userSettingClassifier[_classifier];
  } else if (typeof _classifier !== "number") {
    console.error(
      `Possible user setting classifier strings are: ${Object.keys(
        userSettingClassifier
      ).join(", ")}`
    );
    return null;
  }

  return classifier;
};

/**
 * Retrieves user settings for the given classifier.
 *
 * @param {_classifier} - The classifier string or numeric identifier for the settings.
 * @returns {Promise<Object>} A promise that resolves to the server response or error response.
 */
const getAllUserSettings = async () => {
  const { data, status } = await makeApiCall({
    globalUserSettingsGet: 1,
  });

  if (status === 200) return data;
};

/**
 * Retrieves user settings for the given classifier.
 *
 * @param {_classifier} - The classifier string or numeric identifier for the settings.
 * @returns {Promise<Object>} A promise that resolves to the server response or error response.
 */
const getUserSetting = async (_classifier) => {
  const classifier = resolveClassifier(_classifier);
  if (!classifier) return;

  return makeApiCall({
    globalUserSettingsGet: 1,
    classifier: classifier,
  });
};

/**
 * Updates user settings on the server.
 *
 * @param {Object} payload - The payload containing user settings to be updated.
 * @param {string|number} payload.classifier - The classifier string or numeric identifier for the settings.
 * @returns {Promise<Object>} A promise that resolves to the server response or error response.
 */
const updateUserSetting = async (payload) => {
  const { classifier: _classifier, json_object } = payload;

  const classifier = resolveClassifier(_classifier);
  if (!classifier) return;

  return makeApiCall({
    globalUserSettingsSet: 1,
    json_object,
    classifier,
  });
};

const SettingsService = {
  getUserSetting,
  updateUserSetting,
  getAllUserSettings,
};
export default SettingsService;
