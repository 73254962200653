import api from "./api";
import StorageService from "./storage.service";

/**
 * Makes an API call with the given data and session ID.
 *
 * @param {Object} data - The data to be sent in the API request.
 * @returns {Promise<Object>} - The response from the API, or the error response if an error occurs.
 */
export const makeApiCall = async (data) => {
  const sid = StorageService.getSid();

  const payload = { ...data, sid };

  try {
    const response = await api.post("/", payload);
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    return error.response;
  }
};
